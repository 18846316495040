<template>
  <div>
    <div v-if="nombreHighlights">
      <router-link
        v-for="(highlight, index) in nombreHighlights"
        :key="index"
        class="link"
        style="margin-right: 2px;"
        :class="{ yellow: highlight.type == 'hit' }"
        :to="`usuario/${idUsuario}`"
        >{{ highlight.value }}</router-link
      >
    </div>
    <div v-else>
      <router-link class="link" :to="`usuario/${idUsuario}`">{{
        nombreUsuario
      }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    highlights: {
      type: Array,
      default: () => [],
    },
    idUsuario: {
      type: String,
      required: true,
    },
    nombreUsuario: {
      type: String,
      required: true,
    },
  },

  computed: {
    nombreHighlights() {
      if (this.highlights.length) {
        const hNombre = this.highlights.find((h) => h.path == "nombreCompleto");
        return hNombre ? hNombre.texts : null;
      }
      return [];
    },
  },
};
</script>
